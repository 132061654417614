.html-preview h1 {
  font-size: 2em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview h2 {
  font-size: 1.75em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview h3 {
  font-size: 1.5em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview h4 {
  font-size: 1.25em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview h5 {
  font-size: 1em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview h6 {
  font-size: 0.875em;
  /* font-weight: bold; */
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.html-preview p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.html-preview blockquote {
  border-left: 4px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  color: #666;
  font-style: italic;
}

.html-preview pre {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 3px;
  margin: 1em 0;
  overflow-x: auto;
}

.html-preview code {
  background: #f5f5f5;
  padding: 3px 5px;
  border-radius: 3px;
  font-family: "Courier New", Courier, monospace;
}

.html-preview ul {
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 40px;
}

.html-preview ol {
  list-style-type: decimal;
  margin: 1em 0;
  padding-left: 40px;
}

.html-preview li {
  margin: 0.5em 0;
}

.html-preview a {
  color: #3490dc;
  text-decoration: none;
}

.html-preview img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em 0;
}

.html-preview hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

.html-preview table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.html-preview th,
.html-preview td {
  border: 1px solid #ddd;
  padding: 8px;
}

.html-preview th {
  background-color: #f4f4f4;
  font-weight: bold;
}
