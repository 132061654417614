/* HTML: <div class="loader"></div>
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
} */
@keyframes l15reverse {
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}
/* .loader {
  width: 35px;
  height: 35px;
  border-top: 2px solid gray;
  border-left: 2px solid gray;
  border-radius: 50%;
  padding: 5px;
  animation: l15 0.8s infinite linear;
} */

.loaderInner {
  width: 15px;
  height: 15px;
  border-top: 2px solid gray;
  border-radius: 50%;
  /* animation: l15reverse 1s infinite linear; */
  rotate: 50deg;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
}
.parent {
  position: relative;
  width: fit-content;
}
.loader {
  width: 35px;
  height: 35px;
  border-top: 4px solid gray;
  /* border-left: 2px solid gray; */
  border-radius: 50%;
  padding: 5px;
  animation: l15 0.8s infinite linear;
  position: relative;
}
