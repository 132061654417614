/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@500;600;800&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@300;400;500&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.bg-dark {
  background-color: #282b30;
}
.bg-light {
  background-color: #eeeeee;
}
/* DARK */
.bg-dark-neu {
  border-radius: 10px;
  background: #282b30;
  box-shadow: 5px 5px 10px #111111, -5px -5px 10px #444444;
}
.bg-dark-neu-nr {
  background: #282b30;
  box-shadow: 5px 5px 10px #111111, -5px -5px 10px #444444;
}
/* LIGHT */
.bg-light-neu {
  border-radius: 10px;
  background: #eeeeee;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
}
.bg-light-neu-nr {
  background: #eeeeee;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
}
/* PRIMARY */
.bg-primary-dark-neu {
  border-radius: 10px;
  background: var(--primary-color);
  box-shadow: 5px 5px 10px #111111, -5px -5px 10px #444444;
}
.bg-primary-light-neu {
  border-radius: 10px;
  background: var(--primary-color);
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
}
/* GREEN */
.bg-green-dark-neu {
  border-radius: 10px;
  background: #007c00;
  box-shadow: 5px 5px 10px #111111, -5px -5px 10px #444444;
}
.bg-green-light-neu {
  border-radius: 10px;
  background: #007c00;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
}
/* RED */
.bg-red-dark-neu {
  border-radius: 10px;
  background: #da1f26;
  box-shadow: 5px 5px 10px #111111, -5px -5px 10px #444444;
}
.bg-red-light-neu {
  border-radius: 10px;
  background: #da1f26;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
}

/* BLUR BACKGROUND */
/* LIGHT */
.blur-bg-light,
.blur-bg-light-nr {
  position: relative;
}
.blur-bg-light {
  border-radius: 10px;
}
.blur-bg-light::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(38, 38, 38, 0.2);
}
.blur-bg-light-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(38, 38, 38, 0.2);
}
/* DARK */
.blur-bg-dark,
.blur-bg-dark-nr {
  position: relative;
}
.blur-bg-dark {
  border-radius: 10px;
}
.blur-bg-dark,
.blur-bg-dark-nr {
  position: relative;
}
.blur-bg-dark::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(28, 28, 28, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}
.blur-bg-dark-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(28, 28, 28, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}
/* BLACK */
.blur-bg-black,
.blur-bg-black-nr {
  position: relative;
}
.blur-bg-black {
  border-radius: 10px;
}
.blur-bg-black::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}
.blur-bg-black-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}
/* WHITE */
.blur-bg-white,
.blur-bg-white-nr {
  position: relative;
}
.blur-bg-white {
  border-radius: 10px;
}
.blur-bg-white::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(38, 38, 38, 0.2);
}
.blur-bg-white-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(38, 38, 38, 0.2);
}
/* PRIMARY */
.blur-bg-primary,
.blur-bg-primary-nr {
  position: relative;
}
.blur-bg-primary {
  border-radius: 10px;
}
.blur-bg-primary::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}
.blur-bg-primary-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}

/* GREEN */
.blur-bg-green,
.blur-bg-green-nr {
  position: relative;
}
.blur-bg-green {
  border-radius: 10px;
}
.blur-bg-green::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #007c00;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}
.blur-bg-green-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #007c00;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}

/* RED */
.blur-bg-red,
.blur-bg-red-nr {
  position: relative;
}
.blur-bg-red {
  border-radius: 10px;
}
.blur-bg-red::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #da1f26;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}
.blur-bg-red-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #da1f26;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);
}

/* LOGO BLUE */
.blur-bg-blue,
.blur-bg-blue-nr {
  position: relative;
}
.blur-bg-blue {
  border-radius: 10px;
}
.blur-bg-blue::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #242c3a;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border-radius: 0.75rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}
.blur-bg-blue-nr::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #242c3a;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.2);
}

/* search bar */

.search {
  &__container {
    padding-top: 64px;
  }

  &__title {
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    color: #ff8b88;
  }

  &__input {
    width: 100%;
    padding: 12px 24px;

    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;

    color: #575756;
    background-color: transparent;
    /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: 1px solid #575756;
    transition: all 250ms ease-in-out;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &::placeholder {
      color: color(#575756 a(0.8));
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    &:hover,
    &:focus {
      padding: 12px 0;
      outline: 0;
      border: 1px solid transparent;
      border-bottom: 1px solid #575756;
      border-radius: 0;
      background-position: 100% center;
    }
  }
}

.country-dropdown-container option {
  color: black !important;
}

.credits {
  &__container {
    margin-top: 24px;
  }

  &__text {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }

  &__link {
    color: #ff8b88;
    text-decoration: none;
    transition: color 250ms ease-in;

    &:hover,
    &:focus {
      color: color(#ff8b88 blackness(+25%));
    }
  }
}

/* search bar ends  */

.PhoneInputInput {
  background: none;
}

:root {
  --primary-color: "";
  /* --font-family: "Open Sans", system-ui, Inter, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
  sans-serif; */
  --font-family: "Noto Sans", system-ui, -apple-system, BlinkMacSystemFont,
    Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  --font-size: "12px";
}

html,
body {
  font-family: var(--font-family) !important;
}

.bg-primary {
  background-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color);
}

.border-primary {
  border-color: var(--primary-color);
}

.bg-btn-primary {
  background: var(--primary-color) !important;
  border: 1px solid;
}

.MuiTabs-indicator {
  background: var(--primary-color) !important;
}

.MuiButtonBase-root-MuiButton-root {
  font-family: var(--font-family);
}

/* subscribe btn styling */
.MuiBox-root .css-tykrgw-MuiButtonBase-root-MuiButton-root > a:hover i::before {
  /* left: 20%; */
  width: 20px;
  font-family: var(--font-family);
}

/* bubble chart tooltip */
.custom-tooltip {
  background-color: white;
  color: black;
  /* padding: 10px; */
}

.custom-tooltip h1 {
  background: #e5e7eb;
  color: black;
  font-weight: bold;
  padding: 4px;
  font-family: var(--font-family) !important;
}

.custom-tooltip .custom-data {
  padding: 10px;
  margin-bottom: 3px;
}

/* sidebar icons */
/* .css-wx7wi4{
  display: "none !important";
} */

.x-axis-labels text {
  transform: rotate(-45deg); /* Adjust the degree of rotation as needed */
  transform-origin: center;
}

/* mui date icon */
.reminder > .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium {
  padding-right: "10px !important";
}

html,
body {
  scroll-behavior: smooth;
  /* font-size: 12px; */
  font-size: var(--font-size);
  font-family: var(--font-family) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Example with a specific color (light blue) */
.calendar-container .fc-toolbar {
  background-color: #111827; /* Replace this with the color you want */
  padding: 10px;
  color: #ffffff;
  margin-bottom: -1px !important;
}

.border-l-\[4px\] {
  border-left: 0px !important;
  margin-bottom: 0px !important;
  margin: 1rem 0 !important;
  padding: 0px !important;
}

.border-l-\[4px\] .bg-primary {
  margin-left: 0.5rem !important;
}

/* mui mobile time picker */
.css-3dah0e-MuiModal-root-MuiDialog-root .MuiDialog-paper {
  outline: 0;
  min-width: 320px;
  border: 4px solid var(--primary-color);
  border-radius: 2%;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiDataGrid-panel .MuiSwitch-thumb .css-jsexje-MuiSwitch-thumb {
  color: var(--primary-color) !important;
}

.MuiDataGrid-panel .MuiButtonBase-root .MuiSwitch-switchBase {
  color: var(--primary-color);
  font-family: var(--font-family);
}

.MuiDataGrid-panel .MuiSwitch-root .MuiSwitch-track {
  background-color: var(--primary-color);
}
.MuiDataGrid-panel
  .css-1ycxvqq-MuiSwitch-root
  .MuiSwitch-thumb
  .MuiSwitch-thumb
  .css-jsexje-MuiSwitch-thumb {
  color: var(--primary-color) !important;
  font-family: var(--font-family);
}

/* SYNCFUSION COMPONENTS CSS */
@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.ps-menu-button:hover {
  color: black !important;
  font-family: var(--font-family);
}

/* .sidebar-root > .ps-menu-root > ul > div > div > li > a > .ps-menu-label{
  color: var(--primary-color);
  font-weight: bold;
} */

/* .sidebar-root > .ps-menu-root > ul > div > div > li > a > .ps-menu-icon{
  color: var(--primary-color);
  font-size: 16px;
} */

.sidebar-root
  > .ps-menu-root
  > ul
  > div
  > div
  > li
  > a
  > .ps-submenu-expand-icon {
  color: #777777;
}

.ps-submenu-content {
  border-radius: 15px !important;
}

.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.subscribe_btn:hover > .btn_i_right {
  left: 20% !important;
  width: 20px !important;
}

.subscribe_btn:hover > .btn_i_left {
  left: 80% !important;
  width: 20px !important;
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 50px;
  opacity: 0.5 !important;
}

.dark-mode ::-webkit-scrollbar-thumb {
  /* background-color: rgb(216, 216, 216); */
  background-color: #333333;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.dark-mode .PhoneInputCountryIconImg {
  color: #fff;
}

.PhoneInput {
  padding: 7px !important;
}

.image-picker .progress-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background-color: white;
}

.dark-mode-sidebar .ps-submenu-content > ul div:not(.sub) a span {
  color: white;
  font-family: var(--font-family);
}
.dark-mode-sidebar .ps-submenu-content > ul > a span {
  color: white;
  font-family: var(--font-family);
}
/* 
.dark-mode-sidebar .top-level-dropdown > .ps-submenu-content > ul > a .ps-menu-label span {
  color: black;
}  */

.dark-mode-sidebar .ps-submenu-content {
  /* background: #1c1c1c; */
}

/* .css-edu7i6 {
  border-radius: 20px !important;
  margin: 10px !important;
} */

.dark-mode-sidebar
  .ps-submenu-content
  > ul
  .ps-menu-button:not(.ps-active):hover
  span {
  color: black;
  font-family: var(--font-family);
}

.image-picker .progress-bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 50000;
  transition: all 1.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

aside.ps-sidebar-root {
  width: 200px;
  min-width: 200px;
}

.css-b3tsul.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
}

/* Data tables stylings */
.MuiDataGrid-columnHeadersInner {
  width: 100% !important;
}

.MuiDataGrid-columnHeadersInner > div {
  width: 100% !important;
}

.MuiDataGrid-cellCheckbox {
  min-width: 25px !important;
  width: 25px !important;
}

.MuiDataGrid-main .MuiDataGrid-cellCheckbox .MuiButtonBase-root {
  padding: 0 3px !important;
  font-family: var(--font-family);
}

.MuiDataGrid-virtualScroller {
  scroll-behavior: smooth !important;
  margin-top: 0 !important;
}

.MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
}

.MuiDataGrid-row {
  padding-left: 0px !important;
  width: 100% !important;
}
.MuiDataGrid-columnHeaders {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  /* min-height: 45px !important; */
  /* font-size: 12px !important; */

  font-family: var(--font-family);
  font-size: var(--font-size) !important;
}

.MuiDataGrid-columnHeaderCheckbox {
  min-width: 22px !important;
  width: 22px !important;
}
.MuiDataGrid-columnHeader .MuiButtonBase-root {
  padding: 0 !important;
  font-family: var(--font-family);
}

.MuiDataGrid-columnHeader[data-field="leadSource"] {
  width: 45px !important;
  min-width: 45px !important;
}

.MuiDataGrid-columnHeader[data-field="leadSource"]
  .MuiDataGrid-columnHeaderDraggableContainer {
  width: 70% !important;
}

.MuiDataGrid-columnHeader[data-field="leadSource"]
  .MuiDataGrid-columnHeaderDraggableContainer
  .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

.MuiDataGrid-cell[data-field="language"] div {
  padding-left: 4px !important;
  width: 100% !important;
}
.MuiDataGrid-cell[data-field="leadSource"] {
  width: 45px !important;
  min-width: 45px !important;
  justify-content: center !important;
}
.MuiDataGrid-cell[data-field="leadSource"] div {
  width: 100% !important;
  justify-content: center !important;
}
.MuiDataGrid-cell[data-field="edit"] {
  width: 100% !important;
  max-width: 100% !important;
}
.MuiDataGrid-cell[data-field="creationDate"] {
  text-align: center !important;
}
.MuiDataGrid-cell {
  padding: 0 !important;
}

.MuiDataGrid-cell[data-field="id"] div {
  width: 100% !important;
  text-align: center !important;
}

.MuiDataGrid-columnHeader {
  padding: 0 !important;
}
.MuiDataGrid-cell .MuiFormControl-root {
  margin: 8px 0 !important;
  width: 100% !important;
}
.MuiDataGrid-main .MuiSelect-select {
  padding: 3px 2px !important;
  padding-right: 18px !important;
  width: 78% !important;
}

.MuiDataGrid-cell[data-field="assignedToManager"] > .MuiBox-root,
.MuiDataGrid-cell[data-field="assignedToSales"] > .MuiBox-root {
  width: 92%;
}

.MuiDataGrid-cell[data-field="feedback"] > .MuiBox-root,
.MuiDataGrid-cell[data-field="priority"] > .MuiBox-root {
  width: 90%;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
  display: none !important;
}
.MuiDataGrid-columnHeader[data-field="edit"]
  .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100% !important;
}

.MuiDataGrid-columnHeader[data-field="edit"]
  .MuiDataGrid-columnHeaderTitleContainerContent
  .MuiDataGrid-columnHeaderTitle {
  width: 100% !important;
  text-align: center !important;
}

label[for="bulkImport"] .MuiButtonBase-root {
  z-index: 5 !important;
  font-family: var(--font-family);
}

.MuiDataGrid-main .MuiDataGrid-cell {
  overflow-wrap: anywhere;
}

.phone-input-light .PhoneInputInput {
  /* background-color: #fff; */
  background-color: transparent;
  color: "#000 !important";
  font-family: var(--font-family);
}

.phone-input-dark .PhoneInputInput {
  /* background-color: #000; */
  background-color: transparent;
  color: "white !important";
  font-family: var(--font-family);
}

.phone-input-light .PhoneInputCountrySelect {
  background-color: #fff !important;
  color: "#000 !important";
  font-family: var(--font-family);
}

.phone-input-dark .PhoneInputCountrySelect {
  background-color: #000 !important;
  color: "white !important";
  font-family: var(--font-family);
}

.MuiTypography-root {
  font-family: var(--font-family);
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: var(--font-family);
}

.MuiDataGrid-cell badge:not(badge.available) {
  background: #000000c7 !important;
  width: 100%;
}

.spinner-container {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}

/* Loader */
.spinner-container circle[fill="#8C37F7"],
.spinner-container circle[fill="#8C37F6"],
.spinner-container circle[fill="#DABDFF"] {
  fill: var(--primary-color) !important;
}

.spinner-container g > circle:first-child {
  stroke: #ff6a6f !important;
}

.MuiDataGrid-columnHeader[aria-label="Action"]
  .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100% !important;
}

.MuiDataGrid-columnHeader[data-field="whatsapp-web"] {
  width: 100% !important;
  max-width: unset !important;
}

.MuiDataGrid-cell[data-field="whatsapp-web"] {
  max-width: unset !important;
  width: 100% !important;
}

.MuiDataGrid-columnHeader[aria-label="Action"] .MuiDataGrid-columnHeaderTitle {
  width: 100% !important;
  text-align: center;
}

.MuiDataGrid-columnHeader:first-child
  .MuiDataGrid-columnHeaderDraggableContainer,
.MuiDataGrid-main .MuiDataGrid-row > div:first-child .MuiDataGrid-cellContent {
  padding-left: 10px;
}

/* mui input label padding remove */
.css-e2zqu4 .MuiFormLabel-root,
.css-e2zqu4 .MuiInputLabel-root,
.css-e2zqu4 .MuiInputLabel-formControl {
  left: 0 !important;
}

.MuiDataGrid-main
  .MuiDataGrid-columnHeaderCheckbox
  .MuiDataGrid-columnHeaderDraggableContainer {
  padding-left: 0 !important;
}

.light-mode-datatable .MuiDataGrid-cell[data-field="leadName"] {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-weight: 500;
}

.dark-mode-datatable .MuiDataGrid-cell[data-field="leadName"] {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-weight: 500;
}

.MuiDataGrid-columnHeaders {
  /* max-height: 45px; */
  /* height: 45px; */
  line-height: 45px;
  white-space: pre-wrap !important;
  text-wrap: wrap;
}
/* 
.MuiDataGrid-cell {
  border-right: 1px solid rgba(224, 224, 224, 1);
} */

.MuiDataGrid-columnHeader[data-field="edit"] {
  max-width: 100% !important;
  width: 100% !important;
  text-align: center;
}

.ps-menuitem-root.ps-active a,
.ps-menuitem-root.ps-active a:hover {
  background: var(--primary-color);
  border-top-right-radius: 0px;
}

.ps-menuitem-root.ps-active .ps-menu-label,
.ps-menuitem-root.ps-active .ps-menu-label:hover,
.ps-menuitem-root.ps-active + .leads_counter,
.ps-menuitem-root.ps-active:hover + .leads_counter,
.ps-menuitem-root.ps-active + .leads_counter:hover {
  color: white !important;
  font-family: var(--font-family);
}

.leads_counter {
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  border-radius: 3px;
  min-width: 40px;
  padding: 1px 2px;
  /* border: 1px solid var(--primary-color); */
}

.light-mode-sidebar .leads_counter {
  color: #ffffff !important;
  background: var(--primary-color) !important;
  font-family: var(--font-family);
}

.dark-mode-sidebar .leads_counter {
  color: #ffffff !important;
  background: #000000 !important;
  font-family: var(--font-family);
}

.ps-menuitem-root .ps-menu-button {
  height: 38px !important;
}

.light-mode-sidebar .ps-open .MuiBox-root {
  /* background: #eeeeee; */
  margin: 0 !important;
}

.light-mode-sidebar .sub .ps-open .MuiBox-root {
  /* background: rgb(255 255 255 / 30%); */
  margin: 0 !important;
}

.dark-mode-sidebar .sub .ps-open .MuiBox-root {
  /* background: rgba(0, 0, 0, 60%); */
  margin: 0 !important;
}

.light-mode-sidebar .ps-open .ps-menu-button:not(.ps-active):hover {
  /* background: #e4e4e4; */
}

.light-mode-sidebar .ps-menu-button.ps-open {
  margin-bottom: 4px;
}

.grid.gap-4 {
  gap: 0.4rem !important;
}

.MuiDataGrid-columnsPanel .css-jsexje-MuiSwitch-thumb {
  background-color: var(--primary-color) !important;
}

.MuiDataGrid-columnsPanel
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: var(--primary-color) !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 0 !important;
}

.css-otzuo3-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: 1px solid var(--primary-color) !important;
}

.MuiDataGrid-root {
  z-index: 1;
}

.MuiDataGrid-cell {
  text-align: center !important;
  justify-content: center !important;
}

.MuiDataGrid-cell[data-field="otp"] > div {
  width: 80%;
}

.closed-datatable
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:last-child {
  /* min-width: revert !important; */
  /* max-width: revert !important; */
  width: 100% !important;
}

.closed-datatable .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  /* min-width: revert !important; */
  /* max-width: revert !important; */
  width: 100% !important;
}
.forms-datatable
  .MuiDataGrid-row
  .MuiDataGrid-cell:last-child
  .MuiDataGrid-cell:first-child {
  /* min-width: revert !important; */
  /* max-width: revert !important; */
  width: 100% !important;
}

.single-emp
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:last-child {
  /* min-width: revert !important; */
  /* max-width: revert !important; */
  width: 100% !important;
}

.single-emp .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  /* min-width: revert !important; */
  /* max-width: revert !important; */
  width: 100% !important;
}

.section-container-light {
  padding: 1rem;
  width: 100%;
  background: #f6f6f6;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
}

.section-container-dark {
  background: none;
  padding: 1rem;
  width: 100%;
  background: black;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
}

.MuiDataGrid-cell[data-field="leadName"] {
  white-space: pre-wrap !important;
  text-wrap: wrap;
}

.MuiDataGrid-cell {
  white-space: pre-wrap !important;
  text-wrap: wrap;
}

@media all and (max-width: 1300px) {
  .MuiDataGrid-cell[data-field="creationDate"] p {
    /* font-size: 12px; */
    font-size: var(--font-size);
  }
}

@media all and (max-width: 1500px) {
  .time-zone-select {
    width: 115px !important;
  }
}

@keyframes whatsappAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.whatsapp-logo-animation {
  animation: whatsappAnimation 2s infinite;
}

.message-received-float {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background: var(--primary-color);
  border-radius: 50%;
  position: fixed;
  bottom: 110px;
  right: 36px;
  z-index: 5000;
}

.message-received-float.animate {
  animation: blink 1s;
}

.message-received-float svg {
  color: white;
}

.active-tab {
  display: block;
  animation: fade 0.4s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  15%,
  35%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* ATTENDANCE  */
/* Customize dark mode calendar */
.custom-dark-calendar {
  background-color: #111827; /* Dark background color */
  color: #ffffff; /* Text color for dark mode */
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001 !important;
}

.popup {
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.popup h2 {
  margin-top: 0;
}

/* .popup button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #000000;
} */

.animate-notif-icon {
  position: relative;
  animation: scale 1s ease-in-out forwards;
}

.animate-notif-icon::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -7px;
  transform: translateY(-50%);
  z-index: 2;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  opacity: 0.3;
  border-radius: 100%;
  animation: breathe2 1s infinite;
}

.animate-notif-icon::after {
  content: "";
  position: absolute;
  top: -240%;
  left: -250%;
  z-index: 3;
  width: 90px;
  height: 90px;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  opacity: 0.2;
  border-radius: 100%;
  animation: breathe 1s infinite;
}

@keyframes scale {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.4);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes breathe2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.notif-fade-out {
  animation: notifFadeOut 0.4s ease-in-out forwards;
}

.Toastify__toast-container--bottom-right {
  width: 450px;
}

.Toastify__toast-container--bottom-right .Toastify__toast {
  padding: 10px;
  background-color: rgb(255 255 255 / 75%);
  backdrop-filter: blur(10px);
}

.Toastify__toast-container--bottom-right .Toastify__toast-body {
  padding: 0;
}

.bell-ring {
  animation: ring 4s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes notifFadeOut {
  0% {
    opacity: 1;
  }

  20% {
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.reminder-container {
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
}

.reminder-line {
  width: 2px;
  height: 0;
  background-color: red;
  position: absolute;
  animation: reminderAnimation 2s infinite;
}

.line-1 {
  left: 10px;
  animation-delay: 0s;
}

.line-2 {
  left: 15px;
  animation-delay: 0.2s;
}

.line-3 {
  left: 20px;
  animation-delay: 0.4s;
}

.line-4 {
  left: 25px;
  animation-delay: 0.6s;
}

@keyframes reminderAnimation {
  0% {
    height: 0;
  }
  50% {
    height: 12px;
  }
  100% {
    height: 0;
  }
}

.Banner4 {
  animation: float 1.5s linear alternate infinite;
}

.animate-profile-pic {
  display: block;
}

.animate-profile-pic img {
  animation: popout 0.15s ease-in forwards;
  pointer-events: none;
}

@keyframes popout {
  0% {
    transform: scale(0.8);
    position: fixed;
    left: 76px;
    top: 56px;
  }

  100% {
    transform: translate(45vw, 45vh) scale(7);
    position: fixed;
  }
}

@keyframes float {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.navbar-menu-backdrop {
  z-index: 15 !important;
}

.navbar-menu-backdrop > div[data-testid="sentinelStart"] {
  display: block;
}

.filters-menu {
  z-index: 35 !important;
}

.deal-closed-animation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  animation: slideIn 1s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(130%);
  }

  20% {
    transform: scale(1.5) translateY(-18%);
  }

  100% {
    transform: scale(1) translateY(0);
  }
}

.fade-out-confetti,
.fade-out-popup {
  animation: fadeoutclosedanimation 0.4s ease-in-out forwards;
}

@keyframes fadeoutclosedanimation {
  100% {
    opacity: 0;
  }
}

.OffEx4 {
  /* ex 4 texts  */
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear alternate infinite;
  animation-delay: 0s;
}

.triangle1Ex4 {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 3s linear alternate infinite;
  animation-delay: 2s;
}
.reminder-line {
  width: 2px;
  height: 0;
  background-color: var(--primary-color);
  position: absolute;
  animation: reminderAnimation 2s infinite;
}

.triangle2Ex4 {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 2s linear alternate infinite;
  animation-delay: 3s;
}

.triangle3Ex4 {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 1s linear alternate infinite;
  animation-delay: 4s;
}

.BorderAnimationEx4 {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: BannerBorder 3s linear forwards;
  animation-delay: 0s;
}

/* Global Border Animation   */
@keyframes BannerBorder {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 1;
  }
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.amount-animation {
  animation: amountFade 1s linear alternate infinite;
}

@keyframes amountFade {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  fill: #aaaaaa; /* Set the color for all labels */
}

/* DROPDOWN  */
.MuiButtonBase-root-MuiMenuItem-root {
  background: "#FFFFFF";
}

/*snapchat */

.snapchat-fontFamily {
  font-family: Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif;
}
