/* .analog-clock {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
  }
  
  .clock-face {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .clock-face-background {
    width: 100%;
    height: 100%; 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    }

    .dark-mode {
        background-image: url('../../public/clock_white.svg');
    }

    .light-mode {
        background-image: url('../../public/clock_black.svg');
    }
  
  .center-pin {
    position: absolute;
    transform-origin: center bottom;
    width: 5%;
    height: 5%;
    border: 2px solid #DA1F26;
    border-radius: 50%;
    left: 47.5%;
    top: 47.5%;
    z-index: 500;
  }
  
  .hour-hand,
  .minute-hand,
  .second-hand {
    position: absolute;
    transform-origin: center bottom;
  }
  
  .hour-hand {
    width: 2%;
    height: 22px;
    border-radius: 20px;
    top: 34.8%;
    left: 49%;
    z-index: 4;
  }
  
  .minute-hand {
    width: 1%;
    height: 36px;
    border-radius: 10px;
    top: 26%;
    left: 49.5%;
    z-index: 3;
  }
  
  .second-hand {
    width: 0.4%;
    height: 45px;
    background-color: #DA1F26;
    border-radius: 5px;
    top: 20%;
    left: 49.9%;
    z-index: 2;
  } */

  :root {
    --primary-color: "";
  }

  .clock-container {
    position: fixed;
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 240px;
    height: 240px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    background: #111;
    box-shadow: 0 0 12.5px 1.5px #000, 0 0 5px rgba(0, 0, 0, 0.8) inset;
  }
  .clock-container .spear {
    position: absolute;
    width: 110px;
    height: 1.5px;
    background: var(--primary-color);
    left: 50%;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 200;
    box-shadow: 0 1.5px 1.5px rgba(0, 0, 0, 0.4);
  }
  .clock-container .spear:after {
    content: '';
    position: absolute;
    border: 7px solid transparent;
    border-right-color: var(--primary-color);
    right: 0;
    top: -6px;
  }
  .clock-container .spear:before {
    content: '';
    position: absolute;
    border: 7px solid transparent;
    border-left-color: var(--primary-color);
    left: 1px;
    top: -6px;
  }
  .clock-container .clock-analog {
    width: 220px;
    height: 220px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 10px;
    background: #fff;
    z-index: 5;
    position: relative;
    box-shadow: 0 0 12.5px 1.5px #000 inset;
  }
  .clock-container .clock-analog .second, .clock-container .clock-analog .minute, .clock-container .clock-analog .hour {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
    transition: 0.2s 0.2s ease-in;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .clock-container .clock-analog .second span, .clock-container .clock-analog .minute span, .clock-container .clock-analog .hour span {
    position: absolute;
    width: 10px;
    height: 10px;
    line-height: 10px;
    text-align: center;
    transform-origin: 50%;
    z-index: 5;
  }
  .clock-container .clock-analog .second span:after, .clock-container .clock-analog .minute span:after, .clock-container .clock-analog .hour span:after {
    content: '';
    width: 2px;
    height: 0.5px;
    background: #000;
    position: absolute;
    left: 130%;
    top: -10%;
    opacity: 0.3;
  }
  .clock-container .clock-analog .second span:nth-child(5n + 2):after, .clock-container .clock-analog .minute span:nth-child(5n + 2):after, .clock-container .clock-analog .hour span:nth-child(5n + 2):after {
    width: 3.5px;
    opacity: 1;
    left: 110%;
  }
  .clock-container .clock-analog .hour {
    z-index: 150;
    font-size: 12px;
    color: #000;
    font-weight: bold;
  }
  .clock-container .clock-analog .hour span:after {
    opacity: 1;
    width: 2px;
    height: 0.5px;
    color: #666;
    -moz-transform: translate(2.5px, 6px);
    -ms-transform: translate(2.5px, 6px);
    -webkit-transform: translate(2.5px, 6px);
    transform: translate(2.5px, 6px);
  }
  .clock-container .clock-analog .hour:after {
    content: '';
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 125px;
    height: 125px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 0 7.5px 1px rgba(0, 0, 0, 0.6) inset;
  }
  .clock-container .clock-analog .minute {
    color: #fff;
    font-size: 8px;
    /* font-weight: bold; */
  }
  .clock-container .clock-analog .minute span:after {
    background: #fff;
    -moz-transform: translate(5px, -14px) rotate(-9deg);
    -ms-transform: translate(5px, -14px) rotate(-9deg);
    -webkit-transform: translate(5px, -14px) rotate(-9deg);
    transform: translate(5px, -14px) rotate(-9deg);
  }
  .clock-container .clock-analog .minute:after {
    content: '';
    background: #333;
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 175px;
    height: 175px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 0 12.5px 1px #000 inset;
  }
  .clock-container .clock-analog .second {
    font-size: 8px;
    color: #000;
    font-weight: bold;
  }
  .clock-container .clock-analog .second span:after {
    -moz-transform: translate(2.5px, -20px);
    -ms-transform: translate(2.5px, -20px);
    -webkit-transform: translate(2.5px, -20px);
    transform: translate(2.5px, -20px);
  }
  .clock-container .clock-analog .dail {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
  }
  .clock-container .clock-analog .dail span {
    width: 10px;
    height: 10px;
    line-height: 10px;
    transform-origin: 50%;
    text-indent: 1000px;
    overflow: hidden;
    position: absolute;
  }
  .clock-container .clock-analog .dail span:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #7d7e7d;
    /* *zoom: 1; */
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FF7D7E7D', endColorstr='#FF0E0E0E');
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdkN2U3ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzBlMGUwZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #7d7e7d), color-stop(100%, #0e0e0e));
    background-image: -moz-linear-gradient(left, #7d7e7d 0%, #0e0e0e 100%);
    background-image: -webkit-linear-gradient(left, #7d7e7d 0%, #0e0e0e 100%);
    background-image: linear-gradient(to right, #7d7e7d 0%, #0e0e0e 100%);
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .clock-container .clock-analog .dail span:nth-child(5n + 1):after {
    width: 8px;
  }
  .clock-container .clock-digital {
    position: absolute;
    z-index: 200;
    height: 222px;
    width: 112px;
    background: #090909;
    left: 9px;
    top: 9px;
    border-radius: 110px 0 0 110px;
    box-shadow: 2.5px 0 7.5px #000;
  }
  .clock-container .clock-digital:after {
    content: '';
    position: absolute;
    border: 7.5px solid var(--primary-color);
    border-right: none;
    height: 200px;
    width: 100px;
    border-radius: 110px 0 0 110px;
    left: 12.5px;
    top: 12.5px;
  }
  .clock-container .clock-digital .time {
    background: #111;
    position: absolute;
    right: 0px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    border-radius: 25px;
    font-size: 12px;
    padding: 1px 10px;
    box-shadow: 0 0 7.5px #000 inset;
  }
  .clock-container .clock-digital .day {
    background: #111;
    position: absolute;
    right: 0px;
    bottom: 50px;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 7.5px #000 inset;
    padding: 1px 10px;
    font-size: 10px;
  }
  .clock-container .clock-digital .date {
    background: #111;
    position: absolute;
    right: 0px;
    top: 50px;
    color: #fff;
    border-radius: 10px;
    font-size: 10px;
    box-shadow: 0 0 7.5px #000 inset;
    padding: 1px 10px;
  }
  