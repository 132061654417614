/* MAIN STYLING  */
.chatbox__support {
    background: #f9f9f9;
    height: 450px;
    width: 350px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

/* HEADER */
.chatbox__header {
    /* background: var(--primaryGradient); */
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
    padding: 15px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: var(--primaryBoxShadow);
}

.chatbox__image--header {
    margin-right: 10px;
}

.chatbox__heading--header {
    font-size: 1.2rem;
    color: white;
}

.chatbox__description--header {
    font-size: .9rem;
    color: white;
}

/* Messages */
.chatbox__messages {
    padding: 0 20px;
}

.messages__item {
    margin-top: 10px;
    /* background: #E0E0E0;  */
    padding: 8px 12px;
    max-width: 70%;
}

.messages__item--visitor,
.messages__item--typing {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.messages__item--operator {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    /* background: var(--primary); */
    color: white;
}

/* FOOTER */
.chatbox__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    background: var(--secondaryGradient);
    box-shadow: var(--secondaryBoxShadow);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    /* margin-top: 5px; */
}

.chatbox__footer input {
    border: none;
    padding: 10px 10px;
    border-radius: 30px;
    text-align: center;
}

.chatbox__send--footer {
    /* color: white; */
}

.chatbox__button button,
.chatbox__button button:focus,
.chatbox__button button:visited {
    padding: 10px;
    background: white;
    border: none;
    outline: none;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

/* CHATBOX
=============== */
.chatbox {
    /* position: absolute; */
    bottom: 30px;
    left: 30px;
}

/* CONTENT IS CLOSE */
.chatbox__support {
    display: flex;
    flex-direction: column;
    background: #eee;
    width: 300px;
    height: 350px;
    z-index: -123456;
    opacity: 0;
    transition: all .5s ease-in-out;
}

/* CONTENT ISOPEN */
.chatbox--active {
    transform: translateY(-40px);
    z-index: 123456;
    opacity: 1;
    
}

/* BUTTON */
.chatbox__button {
    text-align: right;
}


/* HEADER */
.chatbox__header {
    position: sticky;
    top: 0;
    /* background: orange; */
}

/* MESSAGES */
.chatbox__messages {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex-direction: column-reverse;
}

.messages__item {
    /* background: orange; */
    max-width: 60.6%;
    width: fit-content;
}

.messages__item--operator {
    margin-left: auto;
}

.messages__item--visitor {
    margin-right: auto;
}

/* FOOTER */
.chatbox__footer {
    position: sticky;
    bottom: 0;
}



/* TYPING  */
.messages__item--typing {
    will-change: transform;
    width: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 15px 20px;
    display: table;
    margin-right: auto;
    position: relative;
    animation: 2s bulge infinite ease-out;
}

.messages__item--typing::before,
.messages__item--typing::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: -2px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
}
.messages__item--typing::after {
      height: 10px;
      width: 10px;
      left: -10px;
      bottom: -10px;
}
span.messages__dot {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    animation: 1s blink infinite;
}
  
@keyframes blink {
    50% {
        opacity: 1;
    }
}
  
@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}
  


