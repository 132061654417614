/* CARD HOVER EFFECT  */
.card-hover {
  transition: transform 0.1s, box-shadow 0.1s;
}

.card-hover:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(170, 170, 170, 0.5);
  transition-delay: 0.1s;
}

/* COIN  */
.jump {
  animation: jump 1.5s infinite ease;
}

@keyframes jump {
  0% {
    top: 0;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0;
  }
}

.coin {
  margin: auto;
  height: 30px;
  width: 30px;
}

.coin .front,
.coin .back {
  position: relative;
  z-index: 10;
  height: 30px;
  width: 30px;
  background: #ffbd0b;
  border-radius: 50%;
  border-top: 3px solid #ffd84c;
  border-left: 3px solid #ffd84c;
  border-right: 3px solid #d57e08;
  border-bottom: 3px solid #d57e08;
  transform: rotate(44deg);
}

.coin .front:before,
.coin .back:before {
  content: "";
  margin: 2.5px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #f0a608;
  border-radius: 50%;
  border-bottom: 1px solid #ffd84c;
  border-right: 1px solid #ffd84c;
  border-left: 1px solid #d57e08;
  border-top: 1px solid #d57e08;
  z-index: 2;
}

.coin .front .currency,
.coin .back .currency {
  overflow: hidden;
  position: absolute;
  color: #ffbd0b;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  transform: rotate(-44deg);
  line-height: 1.85;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 0 1px 0 #cb7407;
  z-index: 3;
  border-radius: 50%;
}

.coin .front .currency:after,
.coin .back .currency:after {
  content: "";
  position: absolute;
  height: 100px;
  width: 40px;
  margin: 20px -65px;
  box-shadow: 50px -23px 0 -10px rgba(255, 255, 255, 0.22),
    85px -10px 0 -16px rgba(255, 255, 255, 0.19);
  transform: rotate(-50deg);
  animation: shine 1.5s infinite ease;
}

@keyframes shine {
  0% {
    margin: 20px -65px;
  }
  50% {
    margin: 75px -85px;
  }
  100% {
    margin: 20px -65px;
  }
}

.coin .front .shapes,
.coin .back .shapes {
  transform: rotate(-44deg);
  position: absolute;
  width: 100%;
  height: 100%;
}

.coin .shadow {
  width: 100%;
  height: 5px;
  left: 0;
  border-radius: 50%;
  z-index: -9;
  margin: 2px 0px 0 0px;
  animation: swift 1.5s infinite ease;
}

.shadow-light-mode {
  background: rgba(0, 0, 0, 0.3);
}

.shadow-dark-mode {
  background: rgba(255, 255, 255, 0.3);
}

@keyframes swift {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
    transform: scale(0.8);
  }
  100% {
    opacity: 0.8;
  }
}

/* GOLD GRADIENT TEXT  */
.gold-grad {
  background: repeating-linear-gradient(
    to bottom left,
    #d57e08 0%,
    #b17b32 5%,
    #be8c3c 10%,
    #d3b15f 20%,
    #faf0a0 30%,
    #d3b15f 40%,
    #ffcd0a 50%,
    #be8c3c 60%,
    #bb8332 70%,
    #d4a245 80%,
    #e1b453 90%,
    #faf0a0 95%,
    #a4692a 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #f0a608;
  /* font-family: 'Raleway', sans-serif; */
  position: relative;
  font-weight: 900;
  margin: 0;
  background-size: 150%;
  filter: drop-shadow(0 0 1px rgba(255, 200, 0, 0.4));
  animation: MoveBackgroundPosition 4s ease-in-out infinite;
}

.gold-grad:after {
  background: none;
  content: attr(data-heading);
  left: 0;
  top: 0;
  position: absolute;
  text-shadow: -1px 0 1px #c6bb9f, 0 1px 1px #c6bb9f,
    5px 5px 10px rgba(0, 0, 0, 0.4), -5px -5px 10px rgba(0, 0, 0, 0.4);
}

@keyframes MoveBackgroundPosition {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* CAROUSEL  */
.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.slide {
  /* background-color: #aaa; */
  width: 100%;
  height: 100%;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  color: #da1f26;
  background-color: #000000;
  border-radius: 50%;
  opacity: 0.2;
  width: 2rem;
  height: 2rem;
  z-index: 1000;
}

.arrow:hover {
  opacity: 1;
  cursor: pointer;
}

.arrow-left {
  left: 1rem;
}

.arrow-right {
  right: 1rem;
}

/* HIDE SCROLLBAR  */
.hide-scrollbar {
  scrollbar-width: 0px; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}


/* booked deals slide */
.modal-open-right-booked {
  animation: slideFromRightBooked 1s ease-in-out forwards; /* Animation duration and easing */
}

.modal-close-right-booked {
  animation: slideToRightBooked 1s ease-in-out; /* Animation duration and easing */
}

/* SLIDE MODAL FROM RIGHT  */
.modal-open-right {
  animation: slideFromRight 1s ease-in-out; /* Animation duration and easing */
}

.modal-close-right {
  animation: slideToRight 1s ease-in-out; /* Animation duration and easing */
}

/* SLIDE MODAL FROM LEFT  */
.modal-open-left {
  animation: slideFromLeft 1s ease-in-out; /* Animation duration and easing */
}

.modal-close-left {
  animation: slideToLeft 1s ease-in-out; /* Animation duration and easing */
}

/* booked deals */
@keyframes slideFromRightBooked {
  /* 0% {
      transform: translateX(100%);
    } */
  100% {
    transform: translate(65%, 30%);
  }
}

@keyframes slideToRightBooked {
  0% {
    transform: translateX(0);
  }
  /* 100% {
      transform: translateX(100%);
    } */
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* SOURCE COUNTERS  */
#fountainG {
  position: relative;
  width: 156px;
  height: 19px;
}

.fountainG {
  position: absolute;
  top: 0;
  background-color: rgb(0, 0, 0);
  width: 19px;
  height: 19px;
  animation-name: bounce_fountainG;
  -o-animation-name: bounce_fountainG;
  -ms-animation-name: bounce_fountainG;
  -webkit-animation-name: bounce_fountainG;
  -moz-animation-name: bounce_fountainG;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(0.3);
  -o-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  border-radius: 12px;
  -o-border-radius: 12px;
  -ms-border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}

#fountainG_1 {
  left: 0;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}

#fountainG_2 {
  left: 19px;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}

#fountainG_3 {
  left: 39px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}

#fountainG_4 {
  left: 58px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

#fountainG_5 {
  left: 78px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}

#fountainG_6 {
  left: 97px;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

#fountainG_7 {
  left: 117px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

#fountainG_8 {
  left: 136px;
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s;
}

@keyframes bounce_fountainG {
  0% {
    transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_fountainG {
  0% {
    -o-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -o-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_fountainG {
  0% {
    -ms-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -ms-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_fountainG {
  0% {
    -webkit-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_fountainG {
  0% {
    -moz-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -moz-transform: scale(0.3);
    background-color: rgb(255, 255, 255);
  }
}

/* ANIMATED SOURCE ICONS */
.icon {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}

.icon.animate {
  transform: translateY(-30px);
}

/* SOURCE COUNTERS MODAL */
.source-counters-open {
  animation: slideFromTop 1s ease-in-out;
}

.source-counters-close {
  animation: slideToTop 1s ease-in-out;
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideToTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* DROPDOWN  */
.react-select__menu {
  z-index: 100000;
}

/* GLASSMORPHISM CARDS */
.green-card {
  background-color: rgba(0, 255, 0, 0.13);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(8, 7, 16, 0.6);
  text-align: center;
}
.red-card {
  background-color: rgba(255, 0, 0, 0.13);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(8, 7, 16, 0.6);
  text-align: center;
}
.black-card {
  background-color: rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(8, 7, 16, 0.6);
  text-align: center;
}
.white-card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(8, 7, 16, 0.6);
}
/* Microphone Blinking  */
@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: yellow;
  }
  100% {
    background-color: white;
  }
}
.listening {
  animation: blink 1s infinite;
}
/* end of Microphone Blinking  */
